import 'regenerator-runtime/runtime';
import './js/scroll';
import './js/router';
import './js/modal';
import './js/gallery';
import './js/anim';
import './js/offices';
import './js/chunks';


import 'reset-css';
import './styles/helpers.scss';
import './styles/fonts.scss';
import './styles/main.scss';
import './styles/modal.scss';
import './styles/menu.scss';
import './styles/footer-contact.scss';
import './styles/sections.scss';
import './styles/advantages.scss';
import './styles/animated-elements.scss';
import '@glidejs/glide/src/assets/sass/glide.core';
import './styles/gallery.scss';
import './styles/grid.scss';
import './styles/offices.scss';
