const dcbModal = document.getElementById("dcbModal");
const openModalBtn = document.getElementById("openModalBtn");
const closeModalBtn = document.getElementById("closeModalBtn");
const bodyEle = document.getElementsByTagName("body")[0];

if (openModalBtn && dcbModal) {
    openModalBtn.onclick = function () {
        openModal();
    }
}

if (closeModalBtn) {
    closeModalBtn.onclick = function () {
        closeModal();
    }
}

document.addEventListener('keydown', function (event) {
    if (event.key === "Escape") {
        if (dcbModal.classList.contains('open')) {
            closeModal();
        }
    }
});

function openModal() {
    bodyEle.classList.add('modal-open');
    dcbModal.classList.add('open');
    openModalBtn.classList.add('hide');
    closeModalBtn.classList.remove('hide');
}

window.closeModal = function() {
    bodyEle.classList.remove('modal-open');
    dcbModal.classList.remove('open');
    openModalBtn.classList.remove('hide');
    closeModalBtn.classList.add('hide');
}