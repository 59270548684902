const floorHeight = 6.6;
const initalBottom = 23.3;

var buildingPlanImg = document.getElementById('buildingPlanImg');
var floors = document.getElementsByClassName('floor-label');
var floorPlanImage = document.getElementsByClassName('floor-plan')[0];
var downloadLinks = document.getElementsByClassName("download-floor")[0].getElementsByTagName("a");
var hoverLock = false;

window.addEventListener('resize', function (event) {
    setFloorLabelLineHeight();
});


for (var i = 0; i < floors.length; i++) {

    var floor = floors[i];
    floor.onclick = function (e) {
        e.preventDefault();
        if (!hoverLock) {
            setFloorLabelSelection(this, floors);
            setFloorOverlay(this.dataset.floor);
        }
    }
}

buildingPlanImg.onclick = function (e) {
    if (!hoverLock) {
        // e = Mouse click event.
        var rect = e.target.getBoundingClientRect();
        var y = e.clientY - rect.top;  //y position within the element.
        var bottom = ((rect.height - y) / rect.height * 100);
        var floorIx = Math.floor((bottom - initalBottom) / floorHeight);

        if (floorIx && floorIx > 0 && floorIx < 10) {
            setFloorLabelSelection(document.querySelectorAll("[data-floor='" + floorIx + "']")[0], floors);
            setFloorOverlay(floorIx);
        }
    }
}

function setFloorLabelSelection(newSelectedFloor, floorList) {
    for (var i = 0; i < floorList.length; i++) {
        var f = floorList[i];
        f.classList.remove('selected');
    }
    newSelectedFloor.classList.add('selected');

    switchFloorPlan(newSelectedFloor.dataset.floor);
}

function setFloorOverlay(floor) {
    var overlay = document.getElementsByClassName("selection-overlay")[0];
    overlay.style.bottom = (parseInt(floor) * floorHeight + initalBottom) + "%";
    overlay.style.opacity = 0.7;
}

window.setFloorLabelLineHeight = function () {
    for (var i = 0; i < floors.length; i++) {
        var floor = floors[i];
        floor.style.lineHeight = ((buildingPlanImg.height * floorHeight / 100) - 2.8) + "px";
    }
}

function switchFloorPlan(floorNumber) {
    hoverLock = true;
    floorPlanImage.classList.add('hidden');
    for (var i = 0; i < downloadLinks.length; i++) {
        downloadLinks[i].classList.remove('visible');
    }
    setTimeout(() => {
        for (var i = 1; i < 10; i++) {
            floorPlanImage.classList.remove('floor-' + i);
        }

        floorPlanImage.classList.add('floor-' + floorNumber);
        floorPlanImage.classList.remove('hidden');

        document.getElementsByClassName("download-floor")[0].getElementsByClassName("f" + floorNumber)[0].classList.add('visible');

        if(screen.width < 1000) {
            floorPlanImage.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }

        hoverLock = false;
    }, 150);
}