import { gsap } from "gsap";
import Glide from '@glidejs/glide';

const route = (event) => {
    event = event || window.event;
    event.preventDefault();
    window.history.pushState({}, "", event.target.href);
    handleLocation();
};

const galleryElements = ["#galerija"];
const officesElements = ["#pisarne", "#nacrti", "#kontakt"];

const handleLocation = async () => {
    const path = window.location.pathname;

    var elementRoute;

    if (path == "/" || path == "/en" || path == "/en/") {
        if (galleryElements.includes(location.hash)) {
            elementRoute = "routeGallery";

        } else if (officesElements.includes(location.hash)) {
            elementRoute = "routeOffices";

        } else {
            elementRoute = "routeHome";

        }
    } else {
        elementRoute = "route404";
    }

    showPage(elementRoute);
    if (location.hash) {
        scrollToSection(location.hash);
    }
};

window.onpopstate = handleLocation;
window.route = route;

handleLocation();

var anchors = document.getElementsByClassName('navigate');
for (var i = 0; i < anchors.length; i++) {
    var anchor = anchors[i];
    anchor.onclick = function (e) {
        e.preventDefault();
        closeModal();
        handleOnClick(this.dataset.route, this.getAttribute("href"));
    }
}

function showPage(routeToShow) {
    var routeEleList = document.getElementsByClassName("route");
    for (var i = 0; i < routeEleList.length; i++) {
        routeEleList[i].style.display = 'none';
    }

    document.getElementById(routeToShow).style.display = 'block';
    document.getElementById("app").className = routeToShow;
    document.getElementsByTagName("main")[0].className = routeToShow;
    setTimeout(function () {
        gsap.to(window, { duration: 0, scrollTo: 0 });
    }, 50)

    if (routeToShow == "routeGallery") {
        glideSlider.update();
    } else if (routeToShow == "routeOffices") {
        setTimeout(() => {
            setFloorLabelLineHeight();
            setOfficesScrollTrigger();
        }, 100);
    }
}

function scrollToSection(section) {
    setTimeout(function () {
        var target = section + "Ele";
        gsap.to(window, { duration: 0.6, scrollTo: target });
    }, 200);
}

function handleOnClick(route, section) {
    var currentRoute = document.getElementById("app").className;
    if (route != currentRoute) {
        showPage(route);
    }

    scrollToSection(section);
}

