import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// MON text
gsap.set("#trMonTxt", {
    duration: 2,
    ease: 'power3.out',
    overwrite: 'auto',
});
ScrollTrigger.create({
    trigger: "#trMonTxt",
    start: 'top 75%',
    end: 'bottom 15%',
    onEnter: () => gsap.to("#trMonTxt", {
        x: -500,
        opacity: 0,
        delay: 2,
    }),
});

// TANA text
gsap.set("#trTanaTxt", {
    duration: 2,
    ease: 'power3.out',
    overwrite: 'auto',
});
ScrollTrigger.create({
    trigger: "#trTanaTxt",
    start: 'top 75%',
    end: 'bottom 15%',
    onEnter: () => gsap.to("#trTanaTxt", {
        x: 500,
        opacity: 0,
        delay: 2,
    }),
});

gsap.utils.toArray('.advantages').forEach(section => {
    // icons
    const elems = section.querySelectorAll('.item');
    gsap.set(elems, {
        x: -50,
        opacity: 0,
        duration: 0.5,
        ease: 'power3.out',
        overwrite: 'auto',
    });

    ScrollTrigger.create({
        trigger: section,
        start: 'top 75%',
        end: 'bottom 15%',
        onEnter: () => gsap.to(elems, {
            x: 0,
            opacity: 1,
            stagger: 0.1,
        }),
        onLeaveBack: () => gsap.to(elems, {
            x: -50,
            opacity: 0,
        }),
    });

    // horizontal line
    const line = section.querySelectorAll('.line');
    gsap.set(line, {
        x: -2000,
        duration: 0.5,
        ease: 'power3.out',
        overwrite: 'auto',
    });

    ScrollTrigger.create({
        trigger: section,
        start: 'top 75%',
        end: 'bottom 15%',
        onEnter: () => gsap.to(line, {
            x: 0,
            delay: 0.5,
        }),
        onLeaveBack: () => gsap.to(line, {
            x: -2000,
        }),
    });

    // vertical lines
    const lines = section.querySelectorAll('.lines');
    gsap.set(lines, {
        x: -500,
        opacity: 0,
        duration: 0.5,
        ease: 'power3.out',
        overwrite: 'auto',
    });

    ScrollTrigger.create({
        trigger: section,
        start: 'top 75%',
        end: 'bottom 15%',
        onEnter: () => gsap.to(lines, {
            x: 0,
            opacity: 1,
            stagger: 0.2,
            delay: 1,
        }),
        onLeaveBack: () => gsap.to(lines, {
            x: -500,
            opacity: 0,
        }),
    });
});


// location map images
const mapTrigger = ".location-map-wrapper";
const mapRoads = ".location-map-roads";
const mapFields = ".location-map-fields";
const mapTexts = ".location-map-texts";
gsap.set(mapRoads, {
    x: -30,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
gsap.set(mapFields, {
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
gsap.set(mapTexts, {
    x: -30,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});

ScrollTrigger.create({
    trigger: mapTrigger,
    start: 'top 40%',
    end: 'bottom 15%',
    onEnter: function () {
        gsap.to(mapRoads, {
            x: 0,
            opacity: 1
        });
        gsap.to(mapFields, {
            opacity: 1,
            delay: 1,
        });
        gsap.to(mapTexts, {
            x: 0,
            delay: 0.5,
            opacity: 1
        });
    },
    onLeaveBack: function() {
        gsap.to(mapRoads, {
            x: -30,
            opacity: 0,
        });
        gsap.to(mapFields, {
            opacity: 0
        });
        gsap.to(mapTexts, {
            x: -30,
            opacity: 0
        }); 
    }
});

const entranceImg = ".entrance";
gsap.set(entranceImg, {
    x: 1000,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
ScrollTrigger.create({
    trigger: entranceImg,
    start: 'top 70%',
    end: 'bottom 15%',
    onEnter: () => gsap.to(entranceImg, {
        x: 0,
        opacity: 1,
    }),
    onLeaveBack: () => gsap.to(entranceImg, {
        x: 1000,
        opacity: 0,
    }),
});

// anim-title
const animTitleZp = "#animTitleZp";
const animTitleN = "#animTitleN";
gsap.set(animTitleZp, {
    x: -1000,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
gsap.set(animTitleN, {
    x: -1000,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
ScrollTrigger.create({
    trigger: animTitleZp,
    start: 'top 70%',
    end: 'bottom 15%',
    onEnter: () => gsap.to(animTitleZp, {
        x: 0,
        opacity: 1,
    }),
    onLeaveBack: () => gsap.to(animTitleZp, {
        x: -1000,
        opacity: 0,
    }),
});
ScrollTrigger.create({
    trigger: animTitleN,
    start: 'top 70%',
    end: 'bottom 15%',
    onEnter: () => gsap.to(animTitleN, {
        x: 0,
        opacity: 1,
    }),
    onLeaveBack: () => gsap.to(animTitleN, {
        x: -1000,
        opacity: 0,
    }),
});

// aside selection
ScrollTrigger.create({
    trigger: "#razsiriteSvojPotencialEle",
    onEnterBack: () => setAsideMenuAttributes(""),
});
ScrollTrigger.create({
    trigger: "#znacilnostiInPrednostiEle",
    onEnter: () => setAsideMenuAttributes("znacilnostiInPrednostiEle"),
    onEnterBack: () => setAsideMenuAttributes("znacilnostiInPrednostiEle"),
});
ScrollTrigger.create({
    trigger: "#napredekEle",
    onEnter: () => setAsideMenuAttributes("napredekEle"),
});

let officesScrollTriggerSet = false;
window.setOfficesScrollTrigger = function() {
    if(!officesScrollTriggerSet) {
        ScrollTrigger.create({
            trigger: "#pisarneEle",
            onEnter: () => setAsideMenuAttributes("pisarneEle"),
            onEnterBack: () => setAsideMenuAttributes("pisarneEle"),
        });
        ScrollTrigger.create({
            trigger: "#nacrtiEle",
            onEnter: () => setAsideMenuAttributes(""),
            onEnterBack: () => setAsideMenuAttributes(""),
        }); 
        ScrollTrigger.create({
            trigger: "#kontaktiEle",
            onEnter: () => setAsideMenuAttributes("kontaktiEle"),
        }); 
    }
    officesScrollTriggerSet = true;
}

function setAsideMenuAttributes(classVal) {
    document.getElementById("asideMenu").className = classVal;
}

// animatedFramedText
const animatedFramedText = "#animatedFramedText";
gsap.set(animatedFramedText, {
    x: -1000,
    opacity: 0,
    duration: 0.5,
    ease: 'power3.out',
    overwrite: 'auto',
});
ScrollTrigger.create({
    trigger: animatedFramedText,
    start: 'top 70%',
    end: 'bottom 35%',
    onEnter: () => setAnimatedFramedText(0, 1, 3),
    onEnterBack: () => setAnimatedFramedText(0, 1, 3), 
    onLeave: () => setAnimatedFramedText(-1000, 0, .5),
    onLeaveBack: () => setAnimatedFramedText(-1000, 0, 0.5),
});

function setAnimatedFramedText(x, opacity, delay) {
    gsap.to(animatedFramedText, {
        x: x,
        opacity: opacity,
        delay: delay,
    });
}