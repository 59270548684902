setTimeout(function() {
    const header = document.getElementsByTagName("header")[0];
    const aside = document.getElementsByTagName("aside")[0];
    const dgbn1 = document.getElementsByClassName("dgnb")[0];
    const dgbn2 = document.getElementsByClassName("dgnb")[1];

    header.style.opacity = "1";
    aside.style.opacity = "1";
    dgbn1.style.opacity = "1";
    dgbn2.style.opacity = "1";
}, 100);
